import { ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';

export default {
  name: 'addLeaseProject',
  components: {
    ModelSelect,
    DatePicker
  },
  props: ['leaseFloorRowDetails'],
  data() {
    return {
      loader: false,
      responseMsg: '',
      showAlert: false,
      isSuccess: false,
      editMode: false,
      parent_value_set_id: null,
      unsubscribe: null,
      leasePrj: {
        value: null,
        text: null
      },
      leaseTwr: {
        value: null,
        text: null
      },
      floorType: {
        value: null,
        text: null
      },
      floorCode: null,
      sequence: 1,
      start_date_end_date: null,
      startDate: null,
      endDate: null,
      floorName: null,
      floorId: 0,
      version: null,
      appStatus: null,
      showValuesetForm: false
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'save' && this.editMode) {
          this.addEditLeaseFloor();
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.floorId });
        }
      }
    });
    if (this.leaseFloorRowDetails) {
      this.fillRecordFromLeaseFloorParent(this.leaseFloorRowDetails);
    } else {
      this.editMode = true;
    }
  },
  methods: {
    fillRecordFromLeaseFloorParent(item) {
      this.floorCode = item.floor_code;
      this.sequence = item.sequence;
      this.floorType.text = item.floor_type_vset_meaning;
      this.start_date_end_date = [
        new Date(item.start_date),
        new Date(item.end_date)
      ];

      this.leaseTwr.text = item.tower_name;
      this.floorName = item.floor_name;
      this.floorType.value = item.floor_type_vset;
      this.leasePrj.text = item.lease_prj_name;
      this.floorId = item.lease_floor_id;
      this.leaseTwr.value = item.lease_tower_id;
      this.leasePrj.value = item.lease_prj_id;
      this.appStatus = item.approval_status;
      this.version = item.version;
    },
    selectedvalueSet(vsetName) {
      if (this.valueSetName === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.leasePrj = {
          text: vsetName.lease_prj_name,
          value: vsetName.value_set_dtl_id
        };
        this.leaseTwr = {
          value: null,
          text: null
        };
      } else if (this.valueSetName === appStrings.VALUESETTYPE.LEASE_TOWER_LIST) {
        this.leaseTwr = {
          text: vsetName.value_code,
          value: vsetName.value_set_dtl_id
        };
      } else if (this.valueSetName === 'FLOOR_TYPE_VSET') {
        this.floorType = {
          text: vsetName.value_meaning,
          value: vsetName.value_code
        };
      }
      this.showValuesetForm = false;
    },
    openValueSetModal(vsetName) {
      if (vsetName === 'FLOOR_TYPE_VSET') {
        this.parent_value_set_id = null;
      } else if (vsetName === appStrings.VALUESETTYPE.LEASE_TOWER_LIST) {
        this.parent_value_set_id = this.leasePrj.value;
      }
      this.valueSetName = vsetName;
      this.showValuesetForm = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetName,
          multiFlag: false
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValuesetForm = false;
    },
    addEditLeaseFloor() {
      const payload = {
        floor_code: this.floorCode,
        lease_floor_id: this.floorId,
        floor_name: this.floorName,
        floor_type_vset: this.floorType.value,
        sequence: this.sequence,
        start_date: commonHelper.formattedDate(this.start_date_end_date[0]),
        end_date: commonHelper.formattedDate(this.start_date_end_date[1]),

        lease_tower_id: this.leaseTwr.value
      };
      this.loader = true;
      this.$store
        .dispatch('leaseFloor/addEditLeaseFloorList', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    setDate(dateRange) {
      this.start_date_end_date = commonHelper.setFutureDate(dateRange);
    },
    clearVsetValues(vsetCode){
      switch(vsetCode){
        case 'LEASE_PROJECT_LIST':
          this.leasePrj = {
            text: null,
            value: null
          }
          break;
          case 'LEASE_TOWER_LIST':
            this.leaseTwr = {
              text: null,
              value: null
            }
            break;
            case 'FLOOR_TYPE_VSET':
              this.floorType = {
                text: null,
                value: null
              }
              break;
            }
          } 
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
