import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import store from '../../../../../../store';
import addLeaseFloor from '../leaseFloor/addLeaseFloor';
export default {
  name: 'leaseFloor',
  watch: {
    currentPage: function() {
      this.getLeaseFloorList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getLeaseFloorList();
    }
  },
  components: {
    commonHelper,
    store,
    addLeaseFloor
  },
  props: [
    'showLeaseModal',
    'selectedProject',
    'selectedTower',
    'selectedFloor',
    'showLeaseModalUnit'
  ],
  data() {
    return {
      unsubscribe: null,
      showLeaseFloorModal: false,
      leaseFloorRowDetails: null,
      parent_value_set_id: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      loader: false,
      showValueSetModal: false,
      showAddLeaseModal: false,
      vsetCode: null,
      leasePrj: {
        value: null,
        text: null
      },
      leaseTwr: {
        value: null,
        text: null
      },
      leaseFloorCod: null,
      leaseFlrNam: null,
      leaseFloorData: [],
      leaseFloorFields: [
        {
          key: 'lease_floor_id',
          class: 'd-none'
        },
        {
          key: 'lease_tower_id',
          class: 'd-none'
        },
        {
          key: 'lease_prj_id',
          class: 'd-none'
        },
        {
          key: 'floor_type_vset',
          class: 'd-none'
        },
        {
          key: 'lease_prj_name',
          label: 'Project Name'
        },
        {
          key: 'tower_name'
        },
        {
          key: 'floor_name'
        },
        {
          key: 'floor_code'
        },
        {
          key: 'lease_tower_id'
        },
        {
          key: 'lease_floor_id'
        },
        {
          key: 'floor_type_vset_meaning',
          label: 'Floor Type'
        },
        {
          key: 'lease_prj_type_meaning',
          label: 'Lease Project Type'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'sequence'
        },
        {
          key: 'version'
        },
        {
          key: 'approval_status'
        }
      ]
    };
  },
  mounted() {
    if (this.showLeaseModal) {
      this.leasePrj.value = this.selectedProject.value;
      this.leasePrj.text = this.selectedProject.text;
      this.getLeaseFloorList();
    }
    if (this.showLeaseModalUnit) {
      this.leasePrj.value = this.selectedProject.value;
      this.leasePrj.text = this.selectedProject.text;
      this.leaseTwr = this.selectedTower;
      this.getLeaseFloorList();
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showLeaseFloorModal = true;
          this.leaseFloorRowDetails = null;
        }
        if (actionName === 'download' && !this.showLeaseFloorModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'leaseFloor/getLeaseFloorList',
            'lease-floor',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getLeaseFloorList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        lease_project_id: this.leasePrj.value,
        lease_tower_id: this.leaseTwr.value,
        lease_floor_code: this.leaseFloorCod,
        lease_floor_name: this.leaseFlrNam
      };
      this.loader = true;
      this.$store
        .dispatch('leaseFloor/getLeaseFloorList', this.payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.leaseFloorData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(item) {
      if (this.showLeaseModal || this.showLeaseModalUnit) {
        this.$emit('getSelectedFloor', item);
      } else {
        this.leaseFloorRowDetails = item;
        this.showLeaseFloorModal = true;
      }
    },
    clearSearchFilters() {
      this.leasePrj = {
        value: null,
        text: null
      };
      this.leaseTwr = {
        value: null,
        text: null
      };
      this.leaseFloorCod = null;
      this.leaseFlrNam = null;
      this.parent_value_set_id = null;
      this.leaseFloorData = [];
      this.totalRows = null;
      this.currentPage = 1;
    },
    hideChildModal() {
      this.showLeaseFloorModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.leasePrj = {
          value: item.value_set_dtl_id,
          text: item.lease_prj_name
        };
        this.leaseTwr = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_TOWER_LIST) {
        this.leaseTwr = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
    },
    openValueSetModal(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.LEASE_TOWER_LIST) {
        this.parent_value_set_id = this.leasePrj.value;
      }
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode){
      switch(vsetCode){
        case 'LEASE_PROJECT_LIST':
          this.leasePrj ={
            text: null,
            value: null
          }
          break;
        case 'LEASE_TOWER_LIST':
          this.leaseTwr = {
            text: null,
            value: null
          }
          break;
        }
    }

  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
